export type AdminRole = "admin" | "super-admin"

export interface IAdminUser {
    _id?: string
    name: string
    email: string
    password?: string
    role?: AdminRole
    active?: boolean
}

export class AdminUserEditable implements IAdminUser {
    name: string = "";
    email: string = "";
}

export class AdminUserCreatable extends AdminUserEditable {
    password: string = "";
}

export class AdminUser extends AdminUserCreatable {
    _id: string = "";
    role: AdminRole = "admin";
    active: boolean = false;
}

export interface AdminUserFilter {
    keyword?: string,
    active?: boolean
}

export interface User {
    _id: string

    email: string
    phone: string // e.164

    title: UserTitle
    firstName: string
    lastName: string
    dateOfBirth: string

    referralCode: string
    rejectPromotion: boolean

    address: Address

    temp?: boolean

    createdAt: Date
    registeredAt: Date
}

export interface UserAuth {
    _id: string;
    password?: string
    googleId?: string
    facebookId?: string
}

export interface RegisterPayload {
    email: string
    phone: string; // e.164

    title: UserTitle;
    firstName: string
    lastName: string

    referralCode: string
    rejectPromotion: boolean

    dateOfBirth: string
    address: Address

    password?: string
    googleId?: string
    facebookId?: string
}

export enum UserTitle {
    Mr,
    Ms
}

export enum Area {
    KL = "KL",
    NT = "NT",
    HK = "HK",
    Islands = "Islands"
}

export interface Address {
    street1: string
    street2: string

    district: string
    area: string
    country: string
}

export interface EditProfilePayload {
    email: string
    phone: string; // e.164

    title: UserTitle;
    firstName: string
    lastName: string

    rejectPromotion: boolean

    dateOfBirth: string
    address: Address
}
