import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import HomeView from "../views/HomeView.vue";
import {useAuthStore} from "@/stores/auth";
import pinia from "@/pinia";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        // meta: {layout: 'full-width'}
    },
    {
        path: "/orders",
        name: "orders",
        component: () => import(/* webpackChunkName: "orders" */ "../views/order/OrdersView.vue")
    },
    {
        path: "/orders/:id",
        name: "order",
        component: () => import(/* webpackChunkName: "order" */ "../views/order/OrderEditView.vue")
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
    },
    {
        path: "/banners",
        name: "banners",
        component: () => import(/* webpackChunkName: "banners" */ "../views/BannersView.vue")
    },
    {
        path: "/admins",
        name: "admins",
        component: () => import(/* webpackChunkName: "admins" */ "../views/AdminsView.vue")
    },
    {
        path: "/users",
        name: "users",
        component: () => import(/* webpackChunkName: "users" */ "../views/UsersView.vue")
    },
    {
        path: "/news",
        name: "news",
        component: () => import(/* webpackChunkName: "news" */ "../views/news/NewsView.vue")
    },
    {
        path: "/news/add",
        name: "news-add",
        component: () => import(/* webpackChunkName: "news-add" */ "../views/news/NewsAddView.vue")
    },
    {
        path: "/news/edit/:id",
        name: "news-edit",
        component: () => import(/* webpackChunkName: "news-edit" */ "../views/news/NewsEditView.vue")
    },
    {
        path: "/news-categories",
        name: "news-categories",
        component: () => import(/* webpackChunkName: "news-categories" */ "../views/news/NewsCategoriesView.vue")
    },
    {
        path: "/quotations",
        name: "quotations",
        component: () => import(/* webpackChunkName: "quotations" */ "../views/quotation/QuotationsView.vue")
    },
    {
        path: "/quotations/:id",
        name: "quotation",
        component: () => import(/* webpackChunkName: "quotation" */ "../views/quotation/QuotationDetail.vue")
    },
    {
        path: "/insurance-types",
        name: "insurance-types",
        component: () => import(/* webpackChunkName: "insurance-types" */ "../views/insurance-type/InsuranceTypesView.vue")
    },
    {
        path: "/insurance-types/add",
        name: "insurance-types-add",
        component: () => import(/* webpackChunkName: "insurance-types-add" */ "../views/insurance-type/InsuranceTypesEditView.vue")
    },
    {
        path: "/insurance-types/edit/:id",
        name: "insurance-types-edit",
        component: () => import(/* webpackChunkName: "insurance-types-edit" */ "../views/insurance-type/InsuranceTypesEditView.vue")
    },
    {
        path: "/insurance-types/form/:id",
        name: "insurance-types-form",
        component: () => import(/* webpackChunkName: "insurance-types-form" */ "../views/insurance-type/InsuranceTypesFormView.vue")
    },
    // {
    //   path: '/settings',
    //   name: 'settings',
    //   component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
    // },
    {
        path: "/packages",
        name: "packages",
        component: () => import(/* webpackChunkName: "packages" */ "../views/package/PackagesView.vue")
    },
    {
        path: "/packages/add",
        name: "packages-add",
        component: () => import(/* webpackChunkName: "packages-add" */ "../views/package/PackagesAddView.vue")
    },
    {
        path: "/packages/edit/:id",
        name: "packages-edit",
        component: () => import(/* webpackChunkName: "packages-edit" */ "../views/package/PackagesEditView.vue")
    },
    {
        path: "/insurance-companies",
        name: "insurance-companies",
        component: () => import(/* webpackChunkName: "insurance-companies" */ "../views/insurance-company/InsuranceCompaniesView.vue")
    },
    {
        path: "/contact-requests",
        name: "contact-requests",
        component: () => import(/* webpackChunkName: "contact-requests" */ "../views/contact-request/ContactRequestsView.vue"),
        // meta: {layout: "full-width"}
    },
    {
        path: "/contact-request/:id",
        name: "contact-request",
        component: () => import(/* webpackChunkName: "contact-request" */ "../views/contact-request/ContactRequestDetail.vue"),
    },
    {
        path: "/email-noti/config",
        name: "email-noti-config",
        component: () => import(/* webpackChunkName: "email-noti-config" */ "../views/EmailNotiView.vue"),
    },
    {
        path: "/blogs",
        name: "blogs",
        component: () => import(/* webpackChunkName: "blogs" */ "../views/blog/BlogView.vue")
    },
    {
        path: "/blogs/add",
        name: "blogs-add",
        component: () => import(/* webpackChunkName: "blogs-add" */ "../views/blog/BlogAddView.vue")
    },
    {
        path: "/blogs/edit/:id",
        name: "blogs-edit",
        component: () => import(/* webpackChunkName: "blogs-edit" */ "../views/blog/BlogEditView.vue")
    },
    {
        path: "/blog-categories",
        name: "blog-categories",
        component: () => import(/* webpackChunkName: "blog-categories" */ "../views/blog/BlogCategoriesView.vue")
    },
    {
        path: "/coupons",
        name: "coupons",
        component: () => import(/* webpackChunkName: "coupons" */ "../views/CouponsView.vue")
    },
    {
        path: "/point/config",
        name: "point-config",
        component: () => import(/* webpackChunkName: "point-config" */ "../views/PointConfigView.vue")
    },
];

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path !== "/") {
        const authStore = useAuthStore(pinia);

        return (authStore.isLoggedIn && authStore.userId) ?
            next() :
            next("/");
    } else {
        return next();
    }
});

export default router;
