import {I18nTxt} from "@models/system";

export enum DiscountType {
    Percentage = "percentage",
    Flat = "flat"
}

export interface Coupon {
    _id: number

    code: string
    name: I18nTxt
    description: I18nTxt

    discountType: DiscountType
    discount: number // int
    scope: string[] // package id

    startTime: Date | null
    expiry: Date | null

    createdAt: Date
}

export interface UserCoupon {
    _id?: string
    userId: string
    couponId: number

    used: boolean

    createdAt: Date

    couponData?: Coupon // attach
}

export type CreateCouponPayload = Pick<Coupon, "code" | "name" | "description" | "discountType" | "discount" | "scope" | "startTime" | "expiry">
export type EditCouponPayload = Partial<CreateCouponPayload>

export type UserCouponDto = Omit<UserCoupon, "_id"> & {_id?: string}

export interface CouponFilter {
    keyword?: string
}

export interface UserCouponFilter {
}
