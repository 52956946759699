import dayjs from "dayjs";
import axios from "axios";

export async function getAuthCredential() {
    const credential = localStorage.getCredentialData();
    if(!credential) return null;

    const {
        accessExpiresAt,
        accessToken,
        refreshExpiresAt,
        refreshToken,
        userId
    } = credential;

    if (accessExpiresAt <= dayjs().add(15, "minutes").valueOf()) {
        if (refreshToken &&
            refreshExpiresAt >= Date.now()) {
            // Refresh access token.
            const res = await axios.post("/admin-users/refresh-token", {
                refreshToken
            });
            const data: {
                accessExpiresIn: number,
                accessToken: string,
            } = res.data;
            const updatedCredential = {
                accessExpiresAt: dayjs().add(data.accessExpiresIn, "seconds").valueOf(),
                accessToken: data.accessToken,
                refreshExpiresAt,
                refreshToken,
                userId
            }
            localStorage.setCredentialData(updatedCredential);
            return updatedCredential;
        } else {
            // Cannot refresh.
        }
    }
    return credential;
}
