
import Vue from "vue";
import {Component, Ref} from "vue-property-decorator";
import {emailRule, requiredRule} from "@/utils/inputRules";
import {useAuthStore} from "@/stores/auth";
import {mapState, mapStores} from "pinia";
import {AdminUser} from "@models/user";
import EditUserDialog from "@/components/EditUserDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import EditAdminPasswordDialog from "@/components/EditAdminPasswordDialog.vue";

@Component({
    components: {
        ConfirmDialog,
        EditUserDialog,
        EditAdminPasswordDialog
    },
    computed: {
        // ...mapWritableState(useAuthStore,[
        //     'accessExpiresIn',
        //     'accessToken',
        //     'refreshExpiresIn',
        //     'refreshToken',
        //     'userId'
        // ])
        ...mapState(useAuthStore, [
            "user",
            "isLoggedIn"
        ]),
        ...mapStores(useAuthStore)
    },
    metaInfo: {
        title: "Realife Insurance Admin"
    }
})
export default class HomeView extends Vue {

    // Login.
    @Ref() loginForm: any;
    email = "";
    password = "";
    showPassword = false;
    loginLoading = false;
    emailRules = [
        emailRule
    ];
    passwordRules = [
        requiredRule
    ];

    // Auth.
    readonly authStore!: ReturnType<typeof useAuthStore>;
    readonly user!: AdminUser | null;
    readonly isLoggedIn!: boolean;

    // Edit user.
    editingUser: AdminUser | null = null;

    // Edit password.
    showEditPasswordDialog = false;
    editingPassword = "";

    async clickLogin() {
        if (this.loginForm.validate()) {
            // Login.
            this.loginLoading = true;
            try {
                await this.authStore.login(this.email, this.password);
                this.$toast.success("Logged in successfully!");
            } catch (e) {
                this.$toast.error("Failed to login!");
            }
            this.loginLoading = false;
        }
    }

    clickEditUser() {
        this.editingUser = new AdminUser();
        Object.assign(this.editingUser, this.user);
    }

    async editUserSuccess() {
        if (this.user) {
            await this.authStore.fetchAdminUser(this.user._id);
        }
    }

    clickEditPassword() {
        this.showEditPasswordDialog = true;
    }
}
