import Vue from "vue";
import "./utils/misc";
import App from "./App.vue";

import "@/filters";

import VueMeta from "vue-meta";
import VuetifyToast from "vuetify-toast-snackbar";
import axios, {AxiosHeaders} from "axios";
import VueAxios from "vue-axios";
import {PiniaVuePlugin} from "pinia";

import Sortable from "sortablejs";
import router from "./router";
import vuetify from "./plugins/vuetify";
import pinia from "@/pinia";
import vueDebounce from "vue-debounce";
import {getAuthCredential} from "@/utils/auth";

Vue.use(VueMeta);
Vue.use(VuetifyToast, {
    x: "center", // default
    y: "top", // default
});
Vue.use(vueDebounce);
// Axios.
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.interceptors.request.use(async function (config) {
    if (!config.token) {
        return config;
    }

    const credential = await getAuthCredential();
    if (!credential) {
        return config;
    }

    if (!config.headers) {
        config.headers = new AxiosHeaders();
    }

    config.headers.Authorization = credential.accessToken ? `Bearer ${credential.accessToken}` : "";
    return config;
});
Vue.use(VueAxios, axios);
// Pinia.
Vue.use(PiniaVuePlugin);

// Drag and drop data table sort.
Vue.directive("sortableDataTable", {
    bind(el, binding, vnode) {
        const options = {
            animation: 150,
            onUpdate: function (event: { oldIndex: number | undefined, newIndex: number | undefined }) {
                vnode.componentInstance?.$emit("sorted", event);
            }
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    pinia,
    render: h => h(App)
}).$mount("#app");
