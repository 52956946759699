
    import {Component, Ref, VModel, Vue} from 'vue-property-decorator'
    import {AdminUser, AdminUserCreatable, AdminUserEditable} from "@models/user"
    import {emailRule, requiredRule} from "@/utils/inputRules"
    import ConfirmDialog from "@/components/ConfirmDialog.vue"
    import {mapState} from "pinia"
    import {useAuthStore} from "@/stores/auth"
    import axios, {HttpStatusCode} from "axios"
    import {AuthError} from "@errors/auth"
    @Component({
        components: {
            ConfirmDialog
        },
        computed: {
            ...mapState(useAuthStore, [
                'userId'
            ])
        }
    })
    export default class EditUserDialog extends Vue {

        @VModel({default: null}) editingUser!: AdminUser | null

        @Ref() form: any
        editUserSaveLoading = false
        nameRules = [
            requiredRule
        ]
        emailRules = [
            emailRule
        ]
        passwordRules = [
            (value: string) => value.length >= 6 || 'Password length must be at least 6.'
        ]

        // Auth.
        readonly userId!: string

        async clickSave() {
            if (this.form.validate() && this.editingUser) {
                this.editUserSaveLoading = true
                let userId = this.editingUser._id
                try {
                    if (userId) {
                        const body = Object.assignExists(new AdminUserEditable(), this.editingUser)
                        if (userId === this.userId) {
                            await this.$http.patch("/admin-users/self", body, {token: true})
                        }
                        else {
                            await this.$http.patch(`/admin-users/${userId}`, body, {token: true})
                        }
                    }
                    else {
                        const body = Object.assignExists(new AdminUserCreatable(), this.editingUser)
                        await this.$http.post("/admin-users", body, {token: true})
                    }

                    this.$toast.success("Saved successfully!")
                    this.editingUser = null
                    this.$emit("success")
                }
                catch (e) {
                    let errorMessage = "Failed to save user!"
                    if (axios.isAxiosError(e) && e.response) {
                        if (e.response.status === HttpStatusCode.Forbidden) {
                            errorMessage = `Not allowed to ${userId ? 'edit' : 'add'} user!`
                            this.editingUser = null
                        }
                        if (e.response.status === HttpStatusCode.NotFound) {
                            if (e.response.data.message === AuthError.UserNotFound) {
                                this.editingUser = null
                            }
                        }
                        if (e.response.status === HttpStatusCode.BadRequest) {
                            if (e.response.data.message === AuthError.InvalidEmail) {
                                errorMessage = "Invalid email! Please ensure the email is in correct format."
                            }
                            if (e.response.data.message === AuthError.EmailAlreadyExists) {
                                errorMessage = "Email already used by another user!"
                            }
                        }
                    }
                    this.$toast.error(errorMessage)
                    this.$emit("fail", e)
                }
                this.editUserSaveLoading = false
            }
        }
    }
