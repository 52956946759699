import {CredentialData} from "@models/auth";
import CryptoJS from "crypto-js";

if (!Object.assignExists) {
    Object.assignExists = function <T extends Record<any, any>, U extends Record<any, any>>(target: T, source: U): T {
        Object.keys(source).filter(key => key in target).forEach(key => Object.assign(target, {[key]: source[key]}));
        return target;
    };
}

if (!Storage.prototype.getCredentialData) {
    Storage.prototype.getCredentialData = function (): CredentialData | null {
        const encryptedCredential = localStorage.getItem("credential");
        if (encryptedCredential) {
            const credential = CryptoJS.AES.decrypt(encryptedCredential, process.env.VUE_APP_CREDENTIAL_KEY!).toString(CryptoJS.enc.Utf8);
            return JSON.parse(credential) as CredentialData;
        }
        return null;
    };
}

if (!Storage.prototype.setCredentialData) {
    Storage.prototype.setCredentialData = function (data: CredentialData) {
        const credentialString = JSON.stringify(data);
        localStorage.setItem("credential", CryptoJS.AES.encrypt(credentialString, process.env.VUE_APP_CREDENTIAL_KEY!).toString());
    };
}

export function nextId(existingIds: number[]) {
    existingIds.sort();
    let targetId = -1, lastId = -1;
    for (const id of existingIds) {
        if (id !== lastId + 1) {
            targetId = lastId + 1;
            break;
        } else {
            lastId = id;
        }
    }
    if (targetId === -1) {
        targetId = lastId + 1;
    }
    return targetId;
}

export async function urlToFile(url: string, fileName?: string) {
    const name = fileName || url.split("/").pop() || "";
    try {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], name, {
            type: blob.type,
        });
    } catch (e) {
        // Stub for CORS.
        return new File([], name, {});
    }
}

export function unique<T>(arr: T[]) {
    return Array.from(new Set(arr));
}

export function notEmpty<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}
