export enum PersonalInfoField {
    SALUTATION,
    NAME,
    LAST_NAME,
    PHONE,
    EMAIL
}

export interface IInsuranceTypeConfig {
    _id?: number
    short: string // MC
    pdfClass: string
    cardImageUrl: string
    headerImageUrl: string
    labelZh: string
    labelEn: string // Convert to kebab case url
    active: boolean
    autoQuote: boolean
    description: string // Show only if autoQuote == false
    personalInfoFields: PersonalInfoField[]
    personalInfoBeforeQuote: boolean
    contactWhatsApp: string
}

export class InsuranceTypeConfigEditable implements IInsuranceTypeConfig {
    short: string = "";
    pdfClass: string = "";
    cardImageUrl: string = "";
    headerImageUrl: string = "";
    labelZh: string = "";
    labelEn: string = "";
    active: boolean = false;
    autoQuote: boolean = false;
    description: string = "";
    personalInfoFields: PersonalInfoField[] = [];
    personalInfoBeforeQuote: boolean = false;
    contactWhatsApp: string = "";
}

export class InsuranceTypeConfig extends InsuranceTypeConfigEditable {
    _id: number = 1;
}

export interface InsuranceTypeDescription {
    _id: number
    description: string
}

export const defaultInsuranceTypeList: InsuranceTypeConfig[] = [
    {
        _id: 1,
        short: "MC",
        pdfClass: "MOTOR CYCLE INSURANCE",
        cardImageUrl: "/image/types/motorcycle-card.jpg",
        headerImageUrl: "/image/types/motorcycle-header.jpg",
        labelZh: "電單車保險",
        labelEn: "Motorcycle",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: true,
    },
    {
        _id: 2,
        short: "TR",
        pdfClass: "TRAVEL PERSONAL ACCIDENT",
        cardImageUrl: "/image/types/travel-card.jpg",
        headerImageUrl: "/image/types/travel-header.jpg",
        labelZh: "旅遊保險",
        labelEn: "Travel",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: false,
    },
    {
        _id: 3,
        short: "OS",
        pdfClass: "STUDENT PERSONAL ACCIDENT INSURANCE",
        cardImageUrl: "/image/types/overseas-student-card.jpg",
        headerImageUrl: "/image/types/overseas-student-header.jpg",
        labelZh: "海外留學保險",
        labelEn: "Oversea Student",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: false,
    },
    {
        _id: 4,
        short: "DH",
        pdfClass: "DOMESTIC HELPER INSURANCE",
        cardImageUrl: "/image/types/domestic-helper-card.jpg",
        headerImageUrl: "/image/types/domestic-helper-header.jpg",
        labelZh: "家傭保險",
        labelEn: "Domestic Helper",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: false,
    },
    {
        _id: 5,
        short: "ID",
        pdfClass: "",
        cardImageUrl: "/image/types/interior-decoration-card.jpg",
        headerImageUrl: "/image/types/interior-decoration-header.jpg",
        labelZh: "裝修工程保險",
        labelEn: "Interior Decoration",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: false,
    },
    {
        _id: 6,
        short: "HM",
        pdfClass: "HOME INSURANCE",
        cardImageUrl: "/image/types/home-card.jpg",
        headerImageUrl: "/image/types/home-header.jpg",
        labelZh: "家居財物保險",
        labelEn: "Home",
        active: true,
        autoQuote: true,
        description: "",
        personalInfoFields: [
            PersonalInfoField.SALUTATION,
            PersonalInfoField.NAME,
            PersonalInfoField.LAST_NAME,
            PersonalInfoField.PHONE,
            PersonalInfoField.EMAIL
        ],
        personalInfoBeforeQuote: false,
    },
] as InsuranceTypeConfig[];

export const defaultInsuranceTypeDict = defaultInsuranceTypeList.reduce((prev, type) => {
    prev[type.short] = type;
    return prev;
}, {} as Record<string, InsuranceTypeConfig>);

export interface I18nTxt {
    zh: string,
    en: string
}

export const emptyI18nTxt: I18nTxt = {zh: "", en: ""};
