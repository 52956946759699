
    import {Component, Prop, Ref, VModel, Vue} from 'vue-property-decorator'
    import ConfirmDialog from "@/components/ConfirmDialog.vue"
    @Component({
        components: {
            ConfirmDialog
        },
        computed: {}
    })
    export default class EditAdminPasswordDialog extends Vue {

        @VModel({default: ""}) editingPassword!: string
        @Prop({default: false, type: Boolean}) show!: boolean
        @Prop({default: ""}) userId!: string

        @Ref() form: any
        showPassword = false
        editPasswordSaveLoading = false
        passwordRules = [
            (value: string) => value.length >= 6 || 'Password length must be at least 6.'
        ]

        async clickSave() {
            if (this.form.validate()) {
                this.editPasswordSaveLoading = true
                try {
                    if (this.userId === "") {
                        await this.$http.patch("/admin-users/self/password", {password: this.editingPassword}, {token: true})
                    }
                    else {
                        await this.$http.patch(`/admin-users/${this.userId}/password`, {password: this.editingPassword}, {token: true})
                    }
                    this.$toast.success("Saved successfully!")
                }
                catch (e) {
                    this.$toast.error("Failed to save password!")
                }

                // Close dialog.
                this.closeEditPassword()

                this.editPasswordSaveLoading = false
            }
        }

        closeEditPassword() {
            this.$emit('close')
            this.editingPassword = ""
        }
    }
