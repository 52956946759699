import {
    QuickQuotationQuestion,
    QuickQuotePackage,
    QuickQuotePackageTableRow,
    QuickQuotePackageVariableType,
    QuickQuoteQuestionType
} from "@models/quotation";
import {DiscountType} from "@models/coupon";
import {defaultInsuranceTypeList} from "@models/system";

export function optionCombinations(questions: QuickQuotationQuestion[]): Record<string, string>[] {
    if (questions.length === 0) {
        return [];
    }

    const q = questions.slice(questions.length - 1)[0];
    if (questions.length === 1) {
        return (q.options || []).map(opt => ({
            [q._id]: opt._id
        }));
    }

    let subCombs = optionCombinations(questions.slice(0, questions.length - 1));
    if (q.type === QuickQuoteQuestionType.TEXT_FIELD) {
        return subCombs;
    }
    if (subCombs.length === 0) {
        return (q.options || []).map(opt => ({
            [q._id]: opt._id
        }));
    }

    let combs: any[] = [];
    if (q.condition && q.condition.length) {
        const possibleSubCombs: Record<string, string>[] = [];
        const naSubCombs: Record<string, string>[] = [];
        for (const sc of subCombs) {
            const available = q.condition?.reduce((pass, condition) => {
                return pass && condition.selectedOptions.includes(sc[condition.questionId]);
            }, true as boolean);
            if (available) {
                possibleSubCombs.push(sc);
            } else {
                naSubCombs.push(sc);
            }
        }
        subCombs = possibleSubCombs;
        combs.push(...naSubCombs);
    }
    for (const opt of q.options || []) {
        combs.push(...subCombs.map(sc => ({
            [q._id]: opt._id,
            ...sc
        })));
    }
    return combs;
}

export type QuestionOptionValueLookup = Record<string, Record<string, number>>

export function buildQuestionOptionValueLookup(questions: QuickQuotationQuestion[]) {
    return questions
        .filter(q => [QuickQuoteQuestionType.CHECKBOX, QuickQuoteQuestionType.DROPDOWN].includes(q.type))
        .reduce((dict, question) => {
            if (!question.options) {
                dict[question._id] = {};
            } else {
                dict[question._id] = question.options.reduce((d, opt) => {
                    if (opt.value !== undefined)
                        d[opt._id] = opt.value;
                    return d;
                }, {} as Record<string, number>);
            }
            return dict;
        }, {} as QuestionOptionValueLookup);
}

export function calcOriginalPrice(p: QuickQuotePackage, row: QuickQuotePackageTableRow, questions: QuickQuotationQuestion[], answers: Record<string, string>) {
    const variables = p.variables.filter(v => v.type !== QuickQuotePackageVariableType.DISCOUNT);
    const questionOptionValueLookup = buildQuestionOptionValueLookup(questions);
    let basePrice = p.basePrice;
    for (const v of variables) {
        let variableValue = Number((row.variableValues || {})[v._id] || 0);
        switch (v.type) {
            case QuickQuotePackageVariableType.PERCENTAGE:
                if (v.targetQuestionId === "")
                    variableValue = p.basePrice * variableValue / 100;
                else
                    variableValue = questionOptionValueLookup[v.targetQuestionId][answers[v.targetQuestionId]] * variableValue / 100;
                break;
            case QuickQuotePackageVariableType.VALUE:
                break;
        }
        basePrice += variableValue;
    }
    return basePrice;
}

export function isEmpty(val: null | undefined | any): val is null | undefined {
    return val === null || val === undefined;
}

export function isNotEmpty<T>(val: null | undefined | T): val is T {
    return !isEmpty(val);
}

export function conditionalProp<T, K extends string>(key: K, value: T) {
    if (isEmpty(value)) return {};
    return {[key]: value};
}

export const emailRegex = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    "i");

export const phoneRegex = /^\d{8,}$/;

export function isValidEmail(email: string) {
    return emailRegex.test(email);
}

export function isValidPhone(phone: string) {
    return phoneRegex.test(phone);
}

export function isValidUrl(url: string) {
    const urlPattern = new RegExp("^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$", "i"); // validate fragment locator
    return urlPattern.test(url);
}

export function alphabet(txt: string) {
    return /[a-zA-Z]/.test(txt);
}

export function joinName(lastName: string, firstName: string) {
    const separator = alphabet(lastName + firstName) ? " " : "";
    return [lastName, firstName].join(separator);
}

export function joinTitle(title: string, name: string) {
    if (alphabet(title)) {
        return [title, name].join(" ");
    } else {
        return [name, title].join("");
    }
}

export function formatDiscount(discount: number, discountType: DiscountType) {
    if (discountType === DiscountType.Flat)
        return `- $${discount}`;
    if (discountType === DiscountType.Percentage)
        return `${100 - discount} % off`;
    return "";
}

export function downloadFile(downloadUrl: string, fileName?: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.target = "_blank";
    a.href = downloadUrl;
    a.download = fileName || downloadUrl.split("/").pop() || "file";
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
}

export function round2Dp(v: number) {
    return Math.round(v * 100) / 100;
}

export function ceil2Dp(v: number) {
    return Math.ceil(v * 100) / 100;
}

export function floor2Dp(v: number) {
    return Math.floor(v * 100) / 100;
}

export function applyPercentage(value: number, percentage: number) {
    return value * percentage / 100;
}

export function calLevy(val: number) {
    return val * 0.001;
}

export function calMib(val: number, type?: number) {
    if (type) {
        const motorCycle = defaultInsuranceTypeList.find(t => t.short === "MC");
        if (type !== motorCycle?._id) return 0;
    }
    return val * 0.03;
}
