import {isValidEmail, isValidPhone} from "shared/utils/helpers";

export const requiredRule = (value: any) => !!value || 'Required.'
export const requiredNumberRule = (value: any) => !!value || value === 0 || 'Required.'
export const emailRule = (value: any) => (isValidEmail(value)) || 'Invalid email.'
export const phoneRule = (value: any) => (isValidPhone(value)) || 'Invalid phone.'
export const rangeRule = ({min, max, inclusive}: {min?: number, max?: number, inclusive?: boolean}) => (value: any) => {
    if (inclusive === undefined || inclusive) {
        if (min !== undefined && value < min)
            return `Cannot be less than ${min}`
        if (max !== undefined && value > max)
            return `Cannot be more than ${max}`
    }
    else {
        if (min !== undefined && value <= min)
            return `Cannot be less than or equal to ${min}`
        if (max !== undefined && value >= max)
            return `Cannot be more than or equal to ${max}`
    }

}