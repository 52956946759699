import { render, staticRenderFns } from "./default-layout.vue?vue&type=template&id=b126d124&scoped=true&"
import script from "./default-layout.vue?vue&type=script&lang=ts&"
export * from "./default-layout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b126d124",
  null
  
)

export default component.exports