import { defineStore } from 'pinia'
import Vue from "vue";
import {CredentialData} from "@models/auth"
import {AdminUser} from "@models/user";
import dayjs from "dayjs";

export const useAuthStore = defineStore('auth', {
    // a function that returns a fresh state
    state: (): CredentialData & { user: AdminUser | null } => {
        const credential = localStorage.getCredentialData()
        if (credential) {
            return {
                ...credential,
                user: null
            }
        }
        return {
            accessExpiresAt: 0,
            accessToken: "",
            refreshExpiresAt: 0,
            refreshToken: "",
            userId: "",
            user: null
        }
    },
    getters: {
        isLoggedIn(state): boolean {
            return (!!state.accessToken && state.accessExpiresAt >= Date.now()) ||
                (!!state.refreshToken && state.refreshExpiresAt >= Date.now())
        },
        isSuperAdmin(state): boolean {
            return !!state.user && state.user.role === "super-admin"
        }
    },
    actions: {
        async login(email: string, password: string) {

            const res = await Vue.axios.post("/admin-users/login", {
                email,
                password
            })

            // const res = {
            //     data: {
            //         accessExpiresIn: 5000,
            //         accessToken: "333333333",
            //         refreshExpiresIn: 5000,
            //         refreshToken: "456",
            //         userId: ""
            //     }
            // }

            const {
                accessExpiresIn, // seconds.
                accessToken,
                refreshExpiresIn, // seconds.
                refreshToken,
                userId
            }: {
                accessExpiresIn: number,
                accessToken: string,
                refreshExpiresIn: number,
                refreshToken: string,
                userId: string
            } = res.data
            const d: CredentialData = {
                accessExpiresAt: dayjs().add(accessExpiresIn, "seconds").valueOf(),
                accessToken,
                refreshExpiresAt: dayjs().add(refreshExpiresIn, "seconds").valueOf(),
                refreshToken,
                userId
            }
            this.$patch(d)
            localStorage.setCredentialData(d)

            await this.fetchAdminUser(userId)
        },
        logout() {
            const d: CredentialData = {
                accessExpiresAt: 0,
                accessToken: "",
                refreshExpiresAt: 0,
                refreshToken: "",
                userId: ""
            }
            this.$patch({
                ...d,
                user: null
            })
            localStorage.setCredentialData(d)
        },
        async fetchAdminUser(userId: string) {
            const res = await Vue.axios.get(`/admin-users/${userId}`, {token: true})
            const user: AdminUser = res.data.adminUser
            if (userId === this.$state.userId) {
                this.$patch({
                    user
                })
            }
        }
    },
})
