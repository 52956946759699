
    import {Component, Prop, Vue} from 'vue-property-decorator'
    @Component({
        components: {},
        computed: {}
    })
    export default class ConfirmDialog extends Vue {
        @Prop({default: false, type: Boolean}) show!: boolean
        @Prop({default: false, type: Boolean}) actionLoading!: boolean
        @Prop({default: ""}) title!: string
        @Prop({default: ""}) content!: string
        @Prop({default: "Cancel"}) cancelTitle!: string
        @Prop({default: "Confirm"}) actionTitle!: string
        @Prop({default: "error"}) actionColor!: string
        @Prop({default: "600"}) maxWidth!: string

        close() {
            this.$emit('close')
        }

        cancel() {
            this.$emit('cancel')
        }

        confirm() {
            this.$emit('confirm')
        }
    }
