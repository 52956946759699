import Vue from 'vue';
import Vuetify, {VSnackbar, VBtn, VIcon} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                background: '#f0f2f4',
                surface: '#f2f5f8',
                primary: '#33348D', // web
                secondary: '#49C7A9', // web
                accent: '#048ba8',
                error: '#EC0927', // web
                info: '#2196F3',
                success: '#06d6a0',
                warning: '#FFBB56',
                'grey-light': '#D7D7D7', // web
                grey: '#9B9B9B', // web
                'grey-dark': '#7D7D7D', // web
            }
        }
    }
});
