import {defineStore} from "pinia";

export const useLoadingStore = defineStore("loading", {
    state: () => ({
        loading: false
    }),
    getters: {},
    actions: {
        showLoadingOverlay() {
            this.loading = true;
        },
        removeLoadingOverlay() {
            this.loading = false;
        },
    },
});
