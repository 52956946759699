import {InsuranceCompany} from "@models/insurance-company";
import {I18nTxt, PersonalInfoField} from "@models/system";

export enum QuickQuoteQuestionType {
    TEXT_FIELD,
    DROPDOWN,
    CHECKBOX
}

export class QuickQuotationQuestionCondition {
    questionId: string = "";
    selectedOptions: string[] = [];
}

export class QuickQuotationQuestion {
    _id: string = "";
    label: string = "";
    short: string = "";
    type: QuickQuoteQuestionType = QuickQuoteQuestionType.TEXT_FIELD;
    options?: QuickQuotationQuestionOption[] = [];
    condition?: QuickQuotationQuestionCondition[] = [];
}

export class QuickQuotationQuestionOption {
    _id: string = "";
    label: string = "";
    short: string = "";
    value?: number;

    pdfInterest?: string;
    pdfSumInsured?: string;
}

export class TextFieldQuickQuoteQuestion extends QuickQuotationQuestion {
    type: QuickQuoteQuestionType.TEXT_FIELD = QuickQuoteQuestionType.TEXT_FIELD;
    // options: undefined;
}

export class DropdownQuickQuoteQuestion extends QuickQuotationQuestion {
    type: QuickQuoteQuestionType.DROPDOWN = QuickQuoteQuestionType.DROPDOWN;
    options: QuickQuotationQuestionOption[] = [];
}

export class CheckboxQuickQuoteQuestion extends QuickQuotationQuestion {
    type: QuickQuoteQuestionType.CHECKBOX = QuickQuoteQuestionType.CHECKBOX;
    options: QuickQuotationQuestionOption[] = [];
}

/**
 * Question Set
 */

export interface IQuickQuoteQuestionSet {
    _id?: number
    questions: QuickQuotationQuestion[]
}

export class QuickQuoteQuestionSetEditable implements IQuickQuoteQuestionSet {
    questions: QuickQuotationQuestion[] = [];
}

export class QuickQuoteQuestionSet extends QuickQuoteQuestionSetEditable {
    _id: number = 0;

    constructor(_id: number = 0) {
        super();
        this._id = _id;
    }
}

export class QuickQuoteAnswered {
    _id: string = "";

    type: number = 0; // duplicated
    questionSet: QuickQuoteQuestionSet = new QuickQuoteQuestionSet();
    followedBy?: string;

    personalInformation: Partial<Record<PersonalInfoField, string>> = {};
    answer: Record<string, string> = {};
    created: number = Date.now();
    active: boolean = true;

    resultedPackages: {
        package: QuickQuotePackage,
        row: QuickQuotePackageTableRow,
        sellingPrice: number,
        specialDiscountDeduct: number,
        autoQuotedPolicyPrice: number,
        policyPriceWithLevy: number,
        levy: number,
        mib: number,
    }[] = []; // submit once after answering all questions, auto select when only one?
    selectedPackage?: string; // submit after selected
    rejectPromotion?: boolean;
}

export enum QuickQuotePackageVariableType {
    PERCENTAGE,
    VALUE,
    DISCOUNT
}

export interface QuickQuotePackageVariable {
    _id: string;
    label: string;
    type: QuickQuotePackageVariableType;
    targetQuestionId: string;
    minValue?: number;

    pdfInterest?: string;
    sumInsured?: string;
}

export class QuickQuotePackageTableRow {
    _id: string = "";
    packageId: string = "";
    criteria: Record<string, string> = {}; // {questionId: optionId}
    variableValues?: Record<string, number> = {}; // {variableId: value}
    unavailable?: boolean;
    personalize?: boolean;

    policyDiscount: number = 0;
    specialDiscount: number = 0;
}

export class QuickQuotePackageTableRowBackup {
    _id: string = "";
    type: number = 1;
    rows: QuickQuotePackageTableRow[] = [];

    capturedAt: Date = new Date();
}

export enum PackagePaymentType {
    OnSite = "on-site",
    OffSite = "off-site",
}

export class QuickQuotePackage {
    _id: string = "";
    featureChip: string = "";
    title: string = "";
    pdfInterest: string = "";
    pdfSumInsured: string = "";
    details: string = "";
    type: number = 1;
    basePrice: number = 0;
    active: boolean = true;
    productLeafletFile: string = "";
    policyWordingFile: string = "";
    insuranceCompanyId: number = -1;
    insuranceCompany?: InsuranceCompany;

    paymentType: PackagePaymentType = PackagePaymentType.OnSite;
    offSitePaymentLink: string = "";

    variables: QuickQuotePackageVariable[] = [];

    documentInstruction: I18nTxt = {zh: "請上傳以下文件\n-身份證明文件（香港身份證）", en: "Please upload the following identify documents\n-Identity documents(HKID)"}; // support line break
    underwritingQuestions: I18nTxt[] = [];
    pdfExcess: string = "";
    pdfConditionsExclusion: string = "";
    pdfRemarks: string =
        "WE GREATLY APPRECIATE YOUR EARLY PROCEED." +
        "IF YOU HAVE ANY QUESTIONS, PLEASE DO NOT HESITATE TO CONTACT ABOVE HANDLER AT PHONE NO. 0000 0000 FAX NO. 0000 0000 OR E-MAIL AT ______@realife.com.hk\n" +
        "\n" +
        "IF APPLICANTS CONTINUOUS TO ARRANGE WITH THE PAYMENT OF PREMIUM, IT WILL BE TREATED AS FURTHER PROCESSED OF THE PURCHASING OF THE INSURANCE.";

    purchasable: boolean = false;
}

export interface QuickQuotePackageFilter {
    type?: number
    company?: number
    keyword?: string
}

export interface QuotationRecordFilter {
    active?: boolean,
    keyword?: string,
    insuranceType?: number
    followedBy?: string
    rejectPromotion?: boolean
}
